import React from 'react'
import { FaMoneyBillWave, FaWarehouse } from "react-icons/fa";
import { MdLandscape } from "react-icons/md";
import s from './LgotsCards.module.css'
const LgotsCards = () => {
  return (
    <div className={s.lgotCardWrapper}>
      <div className={s.lgotCard}>
        <FaMoneyBillWave className={s.photo} />
        <div className={s.title}>Налог на прибыль</div>
        <div className={s.lgotCardIncomeWrapper}>
          <div className={s.lgotCardIntoWrapper}>
            <div className={s.subTitle}>Федеральный бюджет </div>
            <div className={s.number}>0 %</div>
            <div className={s.description}>
              0 — 5 лет — 0%
              <br /> 6 — 10 лет — 3 %
            </div>
          </div>
          <div className={s.lgotCardIntoWrapper}>
            <div className={s.subTitle}>Региональный бюджет</div>
            <div className={s.number}>5 %</div>
            <div className={s.description}>
              0 — 5 лет — 5%
              <br /> 6 — 10 лет — 10 %
            </div>
          </div>
        </div>
      </div>
      <div className={s.lgotCard}>
        <FaWarehouse className={s.photo} />
        <div className={s.title}>Налог на имущество</div>
        <div className={s.number}>0 %</div>
        <div className={s.description}>
          0 — 5 лет — 0%
          <br /> 6 — 10 лет — 2,2 %
        </div>
      </div>
      <div className={s.lgotCard}>
        <MdLandscape className={s.photo} />
        <div className={s.title}>Налог на землю</div>
        <div className={s.number}>1,5 %</div>
      </div>
    </div>
  );
}

export default LgotsCards