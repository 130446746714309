import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo3 from '../../images/logo3.png';
import logo from '../../images/novlogo.png';
import s from "./Navbar.module.css";
const Navbar = () => {
  const router = useNavigate();
  return (
    <div className={s.wrapper}>
      <div className={s.logoWrapper}>
        <img
          onClick={() => router(`/about`)}
          src={logo}
          alt=""
          className={s.logo}
        />
        <img
          onClick={() => router(`/about`)}
          src={logo3}
          alt=""
          className={s.logo3}
        />
      </div>
      <div className={s.navbar}>
        <Link className={s.link} to="/about">
          О парке
        </Link>
        <Link className={s.link} to="/place">
          Расположение
        </Link>
        <Link className={s.link} to="/infrastructure">
          Инфраструктура
        </Link>
        <Link className={s.link} to="/residents">
          Резиденты
        </Link>
        <Link className={s.link} to="/uslugi">
          Инвесторам
        </Link>
        <Link className={s.link} to="/contacts">
          Контакты
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
