import React from "react";
import s from "./List.module.css";
import { FaPlaneDeparture } from "react-icons/fa";
const List = () => {
  return (
    <ol className={s.ball}>
      <li>
        <span>80 км</span> - до г. Екатеринбурга
      </li>
      <li>
        <span>0 км</span> - до г. Новоуральска
      </li>
      <li>
        <span>100 км</span> - до аэропорта "Кольцово"{" "}
        <FaPlaneDeparture className={s.planeIcon} />
      </li>
      <li>
        <span>90 км</span> - до автодороги федерального значения г. Пермь – г.
        Екатеринбург
      </li>
      <li>
        <span>10 км</span> - до железнодорожной станции «Верх-Нейвинск»
      </li>
      <li>
        <span>4 км</span> - до железнодорожной станции «Северная»
      </li>
      <li>
        <span>2,5 км</span> - до железнодорожной станции «Зеленая» (промплощадка
        АО «УЭХК»)
      </li>
      <li>
        <span>0 км</span> - по границе земельного участка индустриального парка
        «Новоуральский» проходит существующая автомобильная дорога регионального
        значения «Подъезд к п. Белоречка от км 7+765 а/д «г. Кировград – г.
        Верхний Тагил» (Идентификационный номер автомобильной дороги – 65 ОП РЗ
        65К-4902120), с которой организован въезд на территорию индустриального
        парка «Новоуральский».
      </li>
    </ol>
  );
};

export default List;
