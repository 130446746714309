import { motion } from "framer-motion";
import React, { useState } from "react";
import InvestList from "../components/InvestList/InvestList";
import LgotsCards from "../components/LgotsCards/LgotsCards";
import Modal from "../components/Modal/Modal";
import Largetitle from "../components/UI/Titles/Large/Largetitle";
import Mediumtitle from "../components/UI/Titles/Medium/Mediumtitle";
import UslugiSquare from "../components/UslugiCards/Square/UslugiSquare";
import UslugiCards from "../components/UslugiCards/UslugiCards";
import detmap2 from "../images/detmapv5.png";
import transition from "../transition";
const UslugiPage = () => {
  const [modal, setModal] = useState(false);
  const mediaQuerry = window.matchMedia('(max-width: 1450px)')
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Largetitle >Инвесторам</Largetitle>
      {mediaQuerry.matches ? (
        <>
          <div className="descUslugi">
            <div className="textUslugi">
              <div className="ex1">
                Управляющая компания Индустриального парка «Новоуральский» будет
                оказывать резидентам следующий набор услуг:
              </div>
              <UslugiCards />
              <div className="ex2">
                На первоначальном этапе базовой услугой управляющей компании
                будет предоставление земельных участков в аренду резидентам. В
                дальнейшем перечень услуг будет расширен.
              </div>{" "}
            </div>
            <img
              onClick={() => setModal(true)}
              className="photoUslugi"
              src={detmap2}
              alt=""
            />
            <Modal
              scale="1.2"
              active={modal}
              setActive={setModal}
              photo={detmap2}
            >
              <img className="photoUslugi" src={detmap2} alt="" />
            </Modal>
          </div>
          <Mediumtitle
            style={{
              textAlign: "start",
              fontSize: "26px",
              marginTop: "15px",
              marginBottom: "10px",
            }}
          >
            Свободные площади:
          </Mediumtitle>
          <div className='lgotiUslugi'>
            <div>
              Общая площадь в границах ИП "Новоуральский" составляет{" "}
              <span>256,5 га</span>, в т.ч. площадь
              <UslugiSquare />
            </div>
            Для размещения потенциальных резидентов 1-го этапа 1-й очереди
            свободная полезная площадь составляет <span>16,8 га</span>.
          </div>
        </>
      ) : (
        <div className="descUslugi">
          <div className="textUslugi">
            <div className="ex1">
              Управляющая компания Индустриального парка «Новоуральский» будет
              оказывать резидентам следующий набор услуг:
            </div>
            <UslugiCards />
            <div className="ex2">
              На первоначальном этапе базовой услугой управляющей компании будет
              предоставление земельных участков в аренду резидентам. В
              дальнейшем перечень услуг будет расширен.
            </div>{" "}
            <Mediumtitle
              style={{
                textAlign: "start",
                fontSize: "26px",
                marginTop: "15px",
                marginBottom: "10px",
              }}
            >
              Свободные площади:
            </Mediumtitle>
            <div className="square">
              Общая площадь в границах ИП "Новоуральский" составляет{" "}
              <span>256,5 га</span>, в т.ч. площадь
              <UslugiSquare />
            </div>{" "}
            Для размещения потенциальных резидентов 1-го этапа 1-й очереди
            свободная полезная площадь составляет <span>16,8 га</span>.
          </div>
          <img
            onClick={() => setModal(true)}
            className="photoUslugi"
            src={detmap2}
            alt=""
          />
          <Modal
            scale="1.2"
            active={modal}
            setActive={setModal}
            photo={detmap2}
          >
            <img className="photoUslugi" src={detmap2} alt="" />
          </Modal>
        </div>
      )}
      <div className="uslugiWrapper">
        <div className="lgotiUslugi">
          <Mediumtitle style={{ marginBottom: "15px" }}>Льготы</Mediumtitle>
          <div>
            Резиденты создаваемого индустриального парка «Новоуральский» смогут
            воспользоваться льготами территории опережающего развития, созданной
            в ЗАТО Новоуральск согласно Федерального закона № 473 «О территориях
            опережающего развития».
          </div>{" "}
          <div>
            <p>Налоговые и таможенные льготы и преференции:</p>
            <LgotsCards />
            <p />
          </div>
        </div>
        <div>
          <Mediumtitle style={{ marginTop: "15px", marginBottom: "15px" }}>
            Требования к резидентам
          </Mediumtitle>
          <InvestList />
        </div>
      </div>
    </motion.div>
  );
};

export default transition(UslugiPage);
