import React from 'react'
import Largetitle from '../components/UI/Titles/Large/Largetitle'
import ResidentCard from '../components/Residents/ResidentCard'
import transition from '../transition';
import { motion } from 'framer-motion';

const ResidentsPage = () => {
  let residents = [
    {
      photo: "https://placehold.co/200x200",
      name: "ООО ПК «Деловой металл»",
      description: "Производство железооксидных пигментов",
    },
    {
      photo: "https://placehold.co/200x200",
      name: "ООО «Техпром-Композит»",
      description:
        "Разработка и производство полимерных композиционных материалов",
    },
    {
      photo: "https://placehold.co/200x200",
      name: "ООО «Техпром-Полимер»",
      description:
        "Разработка и производство деталей и изделий из высокотехнологичных полимерных композиционных материалов",
    },
    {
      photo: "https://placehold.co/200x200",
      name: "ООО «Орион»",
      description:
        "Производство металлоконструкций, производство средств индивидуальной защиты от падения с высоты",
    },
    {
      photo: "https://placehold.co/200x200",
      name: "ООО «Стройтехснаб»",
      description: "Производство газопоршневых электрических станций",
    },
    {
      photo: "https://placehold.co/200x200",
      name: "ООО НПП «Керамические системы»",
      description: "Производство керамических кордиеритовых субстратов",
    },
    {
      photo: "https://placehold.co/200x200",
      name: "АО «РариТЭК Холдинг»",
      description:
        "Производство двигателей внутреннего сгорания для автотранспортных средств",
    },
    {
      photo: "https://placehold.co/200x200",
      name: "ООО «Новоуральский машиностроительный завод № 5»",
      description:
        "Производство гидравлического оборудования, приборов и аппаратуры для автоматического регулирования и управления",
    },
  ];
  
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Largetitle>
        Резиденты
      </Largetitle>
      <div className="residentsWrapper">
        {residents.map((resident) => (
          <ResidentCard
            photo={resident.photo}
            name={resident.name}
            description={resident.description}
          />
        ))}
      </div>
    </motion.div>
  );
}

export default transition(ResidentsPage)