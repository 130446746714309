import React from "react";
import s from './UslugiSquare.module.css'
const UslugiSquare = () => {
  return (
    <div className={s.cardsWrapper}>
      <div className={s.card}>
        <div className={s.cardNumber}>76,9 га</div>
        <div className={s.cardText}> 1-ая очередь </div>
      </div>
      <div className={s.card}>
        <div className={s.cardNumber}> 50,8 га </div>
        <div className={s.cardText}>2-ая очередь</div>
      </div>
      <div className={s.card}>
        <div className={s.cardNumber}> 52,4 га </div>
        <div className={s.cardText}>3-я очередь</div>
      </div>
      <div className={s.card}>
        <div className={s.cardNumber}> 76,7 га </div>
        <div className={s.cardText}>резерв</div>
      </div>
    </div>
  );
};

export default UslugiSquare;
