import React from 'react'
import s from './InvestList.module.css'
const InvestList = () => {
  return (
    <div>
      <ol className={s.ball}>
        <li>
          Регистрация юрлица и осуществление деятельности на ТОР «Новоуральск»
        </li>
        <li>
          Осуществление деятельности, не относящейся к перечню «запрещённых»
          ОКВЭД
        </li>
        <li>Отсутствие статуса регионального инвестиционного проекта</li>
        <li>Объем капитальных вложений – не менее 10 млн рублей</li>
        <li>Отсутствие филиалов и представительств за пределами ТОР</li>
        <li>Отсутствие задолженности по налогам и сборам</li>
        <li>
          Отсутствие производства по делу о несостоятельности и/или
          реорганизации или ликвидации юридического лица
        </li>
      </ol>
    </div>
  );
}

export default InvestList