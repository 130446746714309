import React from 'react'
import s from './UslugiCards.module.css'
import { FaKey } from "react-icons/fa";
import { MdMiscellaneousServices } from "react-icons/md";
const UslugiCards = () => {
  return (
    <div className={s.cardsWrapper}>
      <div className={s.card}>
        <FaKey className={s.cardIcon} />
        <div className={s.cardText}>Аренда земельных участков</div>
      </div>
      <div className={s.card}>
        <MdMiscellaneousServices className={s.cardIcon} />
        <div className={s.cardText}>
          Обслуживание и содержание общеплощадочной инфраструктуры, территорий
          общего пользования индустриального парка.
        </div>
      </div>
    </div>
  );
}

export default UslugiCards