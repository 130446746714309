import { BrowserRouter } from "react-router-dom";
import Approuter from "./components/Approuter";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import ScrollToTop from "./routers/ScrollToTop";
import "./styles/App.css";
import Navigation from "./components/Navigation/Navigation";
import NavLogo from "./components/NavLogo/NavLogo";

function App() {
  const mediaQuerry = window.matchMedia('(max-width:780px)')
  
  return (
    <BrowserRouter>
      <ScrollToTop />
      {mediaQuerry.matches ? (
        <div className="navigationSticky">
          <Navigation />
        </div>
      ) : (
        <Navbar />
      )}
      <div className="contentWrapper">
        <div className="mainWrapper">
          <Approuter />
        </div>
        <div className="footerWrapper">
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
