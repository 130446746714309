import React from 'react'
import { GiTeePipe } from "react-icons/gi";
import { AiFillThunderbolt } from "react-icons/ai";
import { FaFire } from "react-icons/fa";
import { IoIosWater } from "react-icons/io";
import s from './InfrastractCards.module.css'
const InfrastructCards = () => {
  return (
    <div className={s.cardsWrapper}>
      <div className={s.card}>
        <GiTeePipe className={s.cardIcon} />
        <div className={s.cardTitle}>Водоотведение</div>
        <div className={s.cardText}>60 м³/сутки</div>
      </div>
      <div className={s.card}>
        <AiFillThunderbolt className={s.cardIcon} />
        <div className={s.cardTitle}>Электроснабжение</div>
        <div className={s.cardText}>16 МВт</div>
      </div>
      <div className={s.card}>
        <FaFire className={s.cardIcon} />
        <div className={s.cardTitle}>Газоснабжение</div>
        <div className={s.cardText}>8000 м³/час</div>
      </div>
      <div className={s.card}>
        <IoIosWater className={s.cardIcon} />
        <div className={s.cardTitle}>Водоснабжение</div>
        <div className={s.cardText}>60 м³/сутки</div>
      </div>
    </div>
  );
}

export default InfrastructCards