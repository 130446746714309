import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import AboutPage from '../pages/AboutPage';
import ContactsPage from '../pages/ContactsPage';
import InfrastructurePage from '../pages/InfrastructurePage';
import NewsPage from '../pages/NewsPage';
import NewsitemPage from '../pages/NewsitemPage';
import PlacePage from '../pages/PlacePage';
import ResidentsPage from '../pages/ResidentsPage';
import UslugiPage from '../pages/UslugiPage';
import { AnimatePresence } from 'framer-motion';

const Approuter = () => {
  let location = useLocation()
  return (
    <AnimatePresence mode="wait" initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route path="/*" element={<Navigate to="/about" replace />} />
        <Route path="/place" element={<PlacePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/infrastructure" element={<InfrastructurePage />} />
        <Route path="/uslugi" element={<UslugiPage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/news/:title" element={<NewsitemPage />} />
        <Route path="/residents" element={<ResidentsPage />} />
        <Route path="/contacts" element={<ContactsPage />} />
      </Routes>
    </AnimatePresence>
  );
}

export default Approuter