import React from "react";
import { IoIosMail, IoMdPhonePortrait } from "react-icons/io";
import s from "./Footer.module.css";
const Footer = () => {
  const mediaQuerry2 = window.matchMedia("(max-width: 400px)");
  return (
    <div className={s.footerWrapper}>
      <div className={s.contacts}>
        <div className={s.adress}>
          { mediaQuerry2.matches ? (
            <div className={s.adress}>
              <div className={s.fLine}>
                624133, г. Новоуральск, Свердловская область, ул. Льва Толстого,
                д. 2А, ком. 208
              </div>
            </div>
          ) : (
            <div className={s.adress}>
              <div className={s.fLine}>
                624133, г. Новоуральск, Свердловская область,
              </div>
              <div className={s.sLine}>ул. Льва Толстого, д. 2А, ком. 208</div>
            </div>
          )}
        </div>
        <div className={s.svyaz}>
          <div className={s.svyazMail}>
            <IoIosMail className={s.mailIcon} />
            novouralsk@atomtor.ru
          </div>
          <div className={s.svyazPhone}>
            <IoMdPhonePortrait className={s.phoneIcon} />
            +7 (922) 229 89 54
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
