import React from "react";
import ReactPlayer from "react-player/file";
const VideoPlayer = (props) => {
  const mediaQuerry2 = window.matchMedia("(max-width: 780px)");
  let settings = {};
  if (mediaQuerry2.matches) {
    settings = {
      playing: true,
      controls: true,
      url: props.video,
      height: "auto",
      width: "100%",
      loop: true,
      volume: 0.1,
      playsinline: true,
      pip: true,
    };
  } else {
    settings = {
      playing: true,
      controls: true,
      url: props.video,
      height: "auto",
      width: "80%",
      loop: true,
      volume: 0.1,
      playsinline: true,
      pip: true,
      style: {margin: '0 auto'}
    };
  }
  return (
    <div>
      <ReactPlayer {...settings} />
    </div>
  );
};

export default VideoPlayer;
