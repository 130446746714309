import React from "react";
import s from './Map.module.css'
const Map = () => {
  return (
    <div className={s.mapBlock}>
      <iframe
        title="map"
        src="https://yandex.ru/map-widget/v1/?ll=60.082407%2C57.238907&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgoxNTAyNTE3NTgzEnjQoNC-0YHRgdC40Y8sINCh0LLQtdGA0LTQu9C-0LLRgdC60LDRjyDQvtCx0LvQsNGB0YLRjCwg0J3QvtCy0L7Rg9GA0LDQu9GM0YHQuiwg0YPQu9C40YbQsCDQm9GM0LLQsCDQotC-0LvRgdGC0L7Qs9C-LCAy0JAiCg1iVHBCFaP0ZEI%2C&z=17.06"
        width="100%"
        height="400"
        frameborder="0"
        allowfullscreen="true"
        className={s.mapFrame}
      ></iframe>
    </div>
  );
};

export default Map;
