import { motion } from "framer-motion";
import React from "react";
import Connect from "../components/Connect/Connect";
import Map from "../components/Map/Map";
import Largetitle from "../components/UI/Titles/Large/Largetitle";
import transition from "../transition";

const ContactsPage = () => {
  return (
    <motion.div
      className="cntcWrapper"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Largetitle>
        Контакты
      </Largetitle>
      <div className="contactsWrapper">
        <Connect />
        <Map />
      </div>
    </motion.div>
  );
};

export default transition(ContactsPage);
