import React from "react";
import NewsCard from "../components/NewsCard/NewsCard";
import Largetitle from "../components/UI/Titles/Large/Largetitle";
import '../styles/App.css'

const NewsPage = () => {
  let news = [
    {
      title: "Заголовок 1",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Venenatis cras sed felis eget velit aliquet.",
      date: "01.01.2024",
      photo: "https://placehold.co/450x350",
    },
    {
      title: "Заголовок 2",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Venenatis cras sed felis eget velit aliquet.",
      date: "01.01.2024",
      photo: "https://placehold.co/450x350",
    },
    {
      title: "Заголовок 3",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Venenatis cras sed felis eget velit aliquet.",
      date: "01.01.2024",
      photo: "https://placehold.co/450x350",
    },
    {
      title: "Заголовок 4",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Venenatis cras sed felis eget velit aliquet.",
      date: "01.01.2024",
      photo: "https://placehold.co/450x350",
    },
    {
      title: "Заголовок 5",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Venenatis cras sed felis eget velit aliquet.",
      date: "01.01.2024",
      photo: "https://placehold.co/450x350",
    },
    {
      title: "Заголовок 6",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Venenatis cras sed felis eget velit aliquet.",
      date: "01.01.2024",
      photo: "https://placehold.co/450x350",
    },
  ];
  return (
    <div>
      <Largetitle >Новости</Largetitle>
      <div className="newsPageWrapper">
        {news.map((news) => (
          <NewsCard
            className="newsCard"
            photo={news.photo}
            data={news.data}
            title={news.title}
            description={news.description}
          />
        ))}
      </div>
    </div>
  );
};

export default NewsPage;
