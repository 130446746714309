import React, { useState } from "react";
import InfrastructCards from "../components/InfastructCards/InfrastructCards";
import Largetitle from "../components/UI/Titles/Large/Largetitle";
import Mediumtitle from "../components/UI/Titles/Medium/Mediumtitle";
import map from "../images/map3.png";
import transition from "../transition";
import Modal from "../components/Modal/Modal";
import { motion } from "framer-motion";
import InfCardsPlace from "../components/InfCardsPlace/InfCardsPlace";
import RoadsCards from "../components/RoadsCards/RoadsCards";
const InfrastructurePage = () => {
  const [modal, setModal] = useState(false);
  const mediaQuerry = window.matchMedia("(max-width: 885px)");
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <Largetitle>Инфраструктура</Largetitle>
      <div className="wrapperInfrastructure">
        <div className="infrastructureText">
          <div>
            В соответствии с проектом планировки территории создание
            индустриального парка предполагается в две очереди.
            <p />
            Первая очередь реализуется в два этапа: первый этап с 2023 по 2026
            годы, второй этап – с 2025 по 2027 годы.
          </div>
          <div>
            Для обеспечения резидентов, заходящих в период первой очереди на 1-м
            этапе, получены технические условия на присоединение к сетям
            инженерного обеспечения:
          </div>
          <InfrastructCards />
          <div className="infrastructureSecond">
            <div>
              <Mediumtitle style={{ marginTop: "30px" }}>
                Подъезд к участку осуществляется
              </Mediumtitle>
              <div className="infraMedia">
                <InfCardsPlace className="infCardsPlace" />
                <img
                  onClick={() => setModal(true)}
                  className="infrastructureImage"
                  src={map}
                  alt=""
                />
              </div>
              <p />
              <div>
                В рамках строительства объектов внутриплощадочной инфраструктуры
                1-й очереди 1 этапа индустриального парка «Новоуральский»
                подъезд на территорию участка осуществляется с автомобильной
                дороги местного значения г. Новоуральск – п. Белоречка.
              </div>
            </div>
            <div>
              <Mediumtitle style={{ marginTop: "30px", marginBottom: "30px" }}>
                Транспортная инфраструктура
              </Mediumtitle>
              <div>
                Система улиц и дорог в границах территории парка организована
                таким образом, чтобы обеспечить:
              </div>
              <p>
                <ol className="ball">
                  <li>
                    Доступ на территорию 1-й очереди 1 этапа индустриального
                    парка автомобильного транспорта с Белореченского шоссе
                    (автомобильная дорога г. Новоуральск – п. Белоречка).
                  </li>
                  <li>
                    Параметры коридоров автодорог, улиц и проездов, а также
                    инженерных коммуникаций были в соответствии с нормативными
                    требованиями.
                  </li>
                </ol>
              </p>
              <div>
                В основу проектного решения улично-дорожной сети (УДС) положен
                принцип оптимальной транспортной доступности до основных фокусов
                тяготения.
              </div>
              <p />
              <div>
                Развитие планировочной структуры предполагает следующую
                структуру УДС:
              </div>
              <p>
                <RoadsCards />
              </p>
            </div>
            <div>
              <Mediumtitle style={{ marginTop: "30px" }}>
                Категория местных улиц и дорог:
              </Mediumtitle>
              <p />
              <ol className="ball">
                <li>
                  Улицы и дороги научно-производственных, промышленных и
                  коммунально-складских районов (ширина полосы движения 3,5 м,
                  число полос 2-4).
                </li>
              </ol>
              <p />
              <div>
                Ширина поперечных профилей улиц и дорог в красных линиях 25-50 м
                для обеспечения размещения элементов профиля нормативных
                размеров, а также коридоров для размещения инженерных сетей.
                Покрытие – асфальтобетон.
              </div>
            </div>
          </div>
        </div>
        <Modal active={modal} setActive={setModal} scale="2">
          <img
            style={{ maxWidth: "1000px" }}
            className="infrastructureImage"
            src={map}
            alt=""
          />
        </Modal>
      </div>
    </motion.div>
  );
};

export default transition(InfrastructurePage);
