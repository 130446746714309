import { motion } from "framer-motion";
import React from "react";
import s from "./Largetitle.module.css";
const Largetitle = ({ children, ...props }) => {
  return (
    <motion.h3
      className={s.text}
      {...props}
      initial={{ opacity: 0, y: "-30px" }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <span>{children}</span>
    </motion.h3>
  );
};

export default Largetitle;
