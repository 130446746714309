import React from "react";
import s from "./RoadsCards.module.css";
import { MdRoundaboutLeft } from "react-icons/md";
import { FaRoad } from "react-icons/fa";
const RoadsCards = () => {
  return (
    <div className={s.cardsWrapper}>
      <div className={s.card}>
        <MdRoundaboutLeft className={s.cardIcon} />
        <div className={s.cardText}>
          Организация кольцевой дороги (магистральной улицы районного значения)
          с дифференциацией въездов внутрь территории для легкового и грузового
          транспорта;
        </div>
      </div>
      <div className={s.card}>
        <FaRoad className={s.cardIcon} />
        <div className={s.cardText}>
          Организация внутренней транспортной сети, обеспечивающей транспортную
          связь территорий с кольцевой дорогой, транспортную взаимосвязь и
          обслуживание территорий.
        </div>
      </div>
    </div>
  );
};

export default RoadsCards;
