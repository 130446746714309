import React from 'react'
import s from './Modal.module.css'
const Modal = ({children, active, setActive, scale}) => {
  const rootClasses = [s.modal];
  const container = {
    transform: `scale(${scale})`,
  };
  if (active) {
    rootClasses.push(s.active);
  }
  return (
    <div className={rootClasses.join(" ")} onClick={() => setActive(false)}>
      <div className={s.modalContent} style={container}>
        {children}
      </div>
    </div>
  );
}

export default Modal