import React from 'react';
import Line from '../components/UI/Line/Line';
import Largetitle from '../components/UI/Titles/Large/Largetitle';
import VideoPlayer from '../components/VideoPlayer/VideoPlayer';
import video from '../videos/rolik.mp4';
import transition from '../transition';
import { motion } from 'framer-motion';


const AboutPage = () => {
  return (
    <motion.div
      className="aboutWrapper"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Largetitle>О парке</Largetitle>
      <div>
        Индустриальный парк &laquo;Новоуральский&raquo; создается в&nbsp;ЗАТО
        город Новоуральск Свердловской области по&nbsp;инициативе органов власти
        региона, муниципальной администрации, и&nbsp;управляющей компании
        территориями опережающего развития Госкорпорации
        &laquo;Росатом&raquo;&nbsp;&mdash; АО&nbsp;&laquo;Атом-ТОР&raquo;. Эта
        инициатива обусловлена необходимостью развития инфраструктуры для
        реализации новых инвестиционных проектов на&nbsp;ТОР
        &laquo;Новоуральск&raquo;.
        <p />
        Согласно Схеме территориального планирования Свердловской области,
        утвержденной постановлением Правительства Свердловской области от
        31.08.2009 г. № 1000-ПП, индустриальный парк «Новоуральский» является
        объектом регионального значения.
        <p />
        Задача диверсификации экономики Новоуральска соответствует экономической
        стратегии страны и региона, долгосрочным целям Госкорпорации «Росатом».
      </div>
      <Line />
      <VideoPlayer video={video} />
    </motion.div>
  );
}

export default transition(AboutPage)