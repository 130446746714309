import React from 'react'
import s from './InfCardsPlace.module.css'
import { MdNorth } from "react-icons/md";
import { MdOutlineSouthEast } from "react-icons/md";
const InfCardsPlace = () => {
  return (
    <div className={s.InfCardsWrapper}>
      <div className={s.InfCardWrapper}>
        <MdNorth className={s.Photo} />
        <div className={s.Title}> С юга </div>
        <div className={s.Text}>
          Со стороны города Новоуральска с проезда Стройиндустрии и
          автомобильной дороги местного значения г. Новоуральск – п.Белоречка
        </div>
      </div>
      <div className={s.InfCardWrapper}>
        <MdOutlineSouthEast className={s.Photo} />
        <div className={s.Title}> С северо-запада </div>
        <div className={s.Text}>
          Со стороны поселка Белоречка (городской округ Верхний Тагил) по
          автомобильной дороге местного значения г. Новоуральск – п. Белоречка,
          не пересекая охраняемый периметр города.
        </div>
      </div>
    </div>
  );
}

export default InfCardsPlace