import React from "react";
import s from './CustomPrevArrow.module.css'
import { IoIosArrowBack } from "react-icons/io";
const CustomPrevArrow = (props) => {
  const {onClick } = props;
  return (
    <div onClick={onClick} className={s.but}>
        <IoIosArrowBack className={s.icon}/>
    </div>
  );
};

export default CustomPrevArrow;
