import React from "react";
import s from './CustomNextArrow.module.css'
import { IoIosArrowForward } from "react-icons/io";
const CustomNextArrow = (props) => {
  const {onClick} = props;
  return (
    <div onClick={onClick} className={s.but}>
      <IoIosArrowForward className={s.icon} />
    </div>
  );
};

export default CustomNextArrow;
