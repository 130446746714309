import React from "react";
import s from "./ResidentCard.module.css";
const ResidentCard = (props) => {
  return (
    <div
      className={s.residentsWrapper}
    >
      <div className={s.textWrapper}>
        <div className={s.name}>{props.name}</div>
        <div className={s.description}>{props.description}</div>
      </div>
    </div>
  );
};

export default ResidentCard;
