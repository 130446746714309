import React from "react";
import { IoIosMail, IoMdMap, IoMdPhonePortrait } from "react-icons/io";
import s from "./Connect.module.css";
const Connect = () => {
  return (
    <div className={s.connectWrapper}>
      <div className={s.connectIconsBlock}>
        <div className={s.adressBlock}>
          <a className={s.link} href="www">
            <div className={s.logoReactWrapper}>
              <IoMdMap className={s.logoReact} />
            </div>
            <div className={s.linkText}>
              <div>624133, г. Новоуральск, Свердловская область,</div>
              <div> ул. Льва Толстого, д. 2А, ком. 208</div>
            </div>
          </a>
        </div>
        <div className={s.mailBlock}>
          <a className={s.link} href="www">
            <div className={s.logoReactWrapper}>
              <IoIosMail className={s.logoReact} />
            </div>
            <div className={s.linkText}>novouralsk@atomtor.ru</div>
          </a>
        </div>
        <div className={s.phoneBlock}>
          <a className={s.link} href="www">
            <div className={s.logoReactWrapper}>
              <IoMdPhonePortrait className={s.logoReact} />
            </div>
            <div className={s.linkText}>+7 (922) 229 89 54</div>
          </a>
        </div>
      </div>
      <div className={s.infoBlock}>
        <div>
          <span className={s.infoText}>ООО</span>
          <span className={s.infoText}> «Атом-ТОР-Новоуральск»</span>
        </div>
        <div>
          <span className={s.infoName}>ИНН:</span>
          <span className={s.infoText}>6682019450</span>
        </div>
        <div>
          <span className={s.infoName}>КПП:</span>
          <span className={s.infoText}>68201001</span>
        </div>
        <div>
          <span className={s.infoName}>ОГРН:</span>
          <span className={s.infoText}>1216600053625</span>
        </div>
      </div>
    </div>
  );
};

export default Connect;
