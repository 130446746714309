import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import map2 from '../../images/map2.png';
import map1 from '../../images/mapip.png';
import CustomNextArrow from "../Arrows/Next/CustomNextArrow";
import CustomPrevArrow from "../Arrows/Prev/CustomPrevArrow";
import s from './Carousel.module.css';
const Carousel = () => {
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    adaptiveHeight: true
  };
  return (
    <div className={s.carou}>
      <Slider {...settings}>
        <img className={s.photo} src={map1} alt="" />
        <img className={s.photo} src={map2} alt="" />
      </Slider>
    </div>
  );
};

export default Carousel;
