import React from 'react'
import s from './NewsCard.module.css'
const NewsCard = (props) => {
  return (
    <div className={s.newsWrapper}>
      <img className={s.photo} src={props.photo} alt="" />
      <div className={s.data}>{props.data}</div>
      <div className={s.title}>{props.title}</div>
      <div className={s.description}>{props.description}</div>
    </div>
  );
}

export default NewsCard