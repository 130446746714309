import React from 'react'
import Carousel from '../components/Carousel/Carousel'
import List from '../components/List/List'
import Largetitle from '../components/UI/Titles/Large/Largetitle'
import Mediumtitle from '../components/UI/Titles/Medium/Mediumtitle'
import transition from '../transition'
import { motion } from 'framer-motion'

const PlacePage = () => {

  const mediaQuerry = window.matchMedia("(max-width: 730px)");
  const mediaQuerry1 = window.matchMedia("(max-width: 960px)");
  const mediaQuerry2 = window.matchMedia("(max-width: 1000px)");
  const mediaQuerry3 = window.matchMedia("(max-width: 730px)");
  const mediaQuerry4 = window.matchMedia("(max-width: 885px)");
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Largetitle >Расположение</Largetitle>
      {mediaQuerry.matches ? (
        <div className="descWrapper">
          <div className="placeDescWrapper">
            <div className="placeDesc">
              Площадка индустриального парка «Новоуральский» расположена на
              территории Свердловской области, в северной части Новоуральского
              городского округа, вне охраняемой территории закрытого
              административно-территориального образования, в границах ТОР
              «Новоуральск».
              <div>
                <p />
              </div>
              С севера и запада от индустриального парка, в непосредственной
              близости проходят границы Кировоградского городского округа и
              городского округа Верхний Тагил. Южнее от индустриального парка
              находится промплощадка градообразующего предприятия АО
              «Уральский электро-химический комбинат».
              <Carousel />
            </div>
            {mediaQuerry2.matches &&
            mediaQuerry1.matches &&
            mediaQuerry3.matches ? (
              <Mediumtitle style={{ margin: "20px auto", width: "100%" }}>
                Расстояние от границ индустриального парка до ближайших
                населенных пунктов и основных транспортных узлов:
              </Mediumtitle>
            ) : mediaQuerry2.matches && mediaQuerry1.matches ? (
              <Mediumtitle style={{ margin: "20px auto", width: "180%" }}>
                Расстояние от границ индустриального парка до ближайших
                населенных пунктов и основных транспортных узлов:
              </Mediumtitle>
            ) : mediaQuerry2.matches ? (
              <Mediumtitle style={{ margin: "20px auto", width: "165%" }}>
                Расстояние от границ индустриального парка до ближайших
                населенных пунктов и основных транспортных узлов:
              </Mediumtitle>
            ) : (
              <Mediumtitle style={{ margin: "20px auto" }}>
                Расстояние от границ индустриального парка до ближайших
                населенных пунктов и основных транспортных узлов:
              </Mediumtitle>
            )}
            <List />
          </div>
        </div>
      ) : (
        <div className="descWrapper">
          <div className="placeDescWrapper">
            <div className="placeDesc">
              Площадка индустриального парка «Новоуральский» расположена на
              территории Свердловской области, в северной части Новоуральского
              городского округа, вне охраняемой территории закрытого
              административно-территориального образования, в границах ТОР
              «Новоуральск».
              <div>
                <p />
              </div>
              С севера и запада от индустриального парка, в непосредственной
              близости проходят границы Кировоградского городского округа и
              городского округа Верхний Тагил. Южнее от индустриального парка
              находится промплощадка градообразующего предприятия АО
              «Уральский электрохимический комбинат».
            </div>

            {mediaQuerry2.matches && mediaQuerry1.matches ? (
              <Mediumtitle style={{ margin: "20px auto", width: "180%" }}>
                Расстояние от границ индустриального парка до ближайших
                населенных пунктов и основных транспортных узлов:
              </Mediumtitle>
            ) : mediaQuerry2.matches ? (
              <Mediumtitle style={{ margin: "20px auto", width: "165%" }}>
                Расстояние от границ индустриального парка до ближайших
                населенных пунктов и основных транспортных узлов:
              </Mediumtitle>
            ) : (
              <Mediumtitle style={{ margin: "20px auto" }}>
                Расстояние от границ индустриального парка до ближайших
                населенных пунктов и основных транспортных узлов:
              </Mediumtitle>
            )}
            <List />
          </div>
          <Carousel />
        </div>
      )}
    </motion.div>
  );
}

export default transition(PlacePage)